import {
  Component,
  Input,
  Output,
  EventEmitter,
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatestWith,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import {SiteConfigService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CloseIcon} from '@app/icons/close.icon';
import {SearchIcon} from '@app/icons';
import {CommonModule} from '@angular/common';
import {FacetIconComponent} from '@app/icons/facet-icon.component';
import {monthLabels} from '../dates';
import {SwiperDirective} from '@app/directives';
import {SwiperOptions} from 'swiper/types';
import {FacetField} from '@app/models';

@Component({
  selector: 'facet-date-wide',
  imports: [
    ChevronIcon,
    CloseIcon,
    SearchIcon,
    CommonModule,
    FacetIconComponent,
    SwiperDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './facet-date-wide.component.html',
  styleUrls: ['./facet-date-wide.component.css'],
})
export class FacetDateWideComponent implements OnInit, OnDestroy {
  @Input({required: true})
  get activeFacet(): string | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: FacetField | null) {
    this._activeFacet = activeFacet;
  }
  private _activeFacet: string | null = null;

  @Input({required: true}) field!: FacetField;
  @Input({required: true}) defaultLabel = '';
  @Input() icon = '';

  @Input({required: true}) options$!: Observable<
    {
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
    }[]
  >;

  @Input({required: true}) selected$!: Observable<
    | {
        FacetName: string;
        FacetValue: string;
        FacetLabel: string;
      }[]
    | null
  >;

  // footer action buttons
  @Input() actionLabel: string | null = null;

  dateOptions$: BehaviorSubject<{
    state: 'loading' | 'error' | 'success';
    options: {
      available: boolean;
      selected: boolean;
      year: string;
      months: {
        available: boolean;
        selected: boolean;
        facet: string;
        label: string;
      }[];
    }[];
  }> = new BehaviorSubject({
    state: 'loading' as 'loading' | 'error' | 'success',
    options: [] as {
      available: boolean;
      selected: boolean;
      year: string;
      months: {
        available: boolean;
        selected: boolean;
        facet: string;
        label: string;
      }[];
    }[],
  });

  siteID;

  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: {
      nextEl: '#date-custom-next',
      prevEl: '#date-custom-prev',
    },
  };

  // outputs
  @Output() toggleFacet = new EventEmitter<FacetField>();
  @Output() toggleOption = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
    Selected: boolean;
  }>();
  @Output() clearFacet = new EventEmitter();
  @Output() performAction = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor(private siteConfigService: SiteConfigService) {
    this.siteID = this.siteConfigService.getConfig().siteID;
  }

  ngOnInit(): void {
    this.options$
      .pipe(
        combineLatestWith(this.selected$),
        map(([options, selected]) => {
          let withSelected: {
            Selected: boolean;
            FacetName: string;
            FacetValue: string;
            FacetLabel: string;
          }[] = [];

          if (!selected) {
            withSelected = options
              .map(option => {
                return {
                  ...option,
                  Selected: false,
                };
              })
              .sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
          } else {
            withSelected = options
              .filter(
                option =>
                  selected.findIndex(
                    selected => selected.FacetValue === option.FacetValue
                  ) === -1
              )
              .map(option => {
                return {
                  ...option,
                  Selected: false,
                };
              })
              .concat(
                selected.map(selected => {
                  return {
                    ...selected,
                    Selected: true,
                  };
                })
              )
              .sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
          }

          // month options
          const yearsToShow = 4;
          const startYear = new Date().getFullYear();
          const endYear = startYear + yearsToShow;

          const dateList: Array<{
            available: boolean;
            selected: boolean;
            year: string;
            months: Array<{
              available: boolean;
              selected: boolean;
              facet: string;
              label: string;
            }>;
          }> = [];

          for (let i = 0; i < endYear - startYear; i++) {
            const yearStr = (startYear + i).toString();
            dateList[i] = {
              available: false,
              selected: false,
              year: yearStr,
              months: [],
            };
            monthLabels.forEach((month, j) => {
              const monthStr = `${j + 1 < 10 ? '0' : ''}${j + 1}`;
              const labelStr = `${yearStr}-${monthStr}`; //"2024-08"
              const facetMatch = withSelected.find(
                facet => facet.FacetValue === labelStr
              );
              let facet: string;
              if (facetMatch) {
                facet = facetMatch.FacetValue;
              } else {
                facet = labelStr;
              }
              const available = facetMatch !== undefined;
              const isSelected = facetMatch?.Selected ?? false;
              if (available) dateList[i].available = true;
              if (isSelected) dateList[i].selected = true;
              dateList[i].months.push({
                available,
                selected: isSelected,
                facet,
                label: month,
              });
            });
          }
          return dateList;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: res => {
          this.dateOptions$.next({
            state: 'success',
            options: res,
          });
        },
        error: error => {
          this.dateOptions$.next({
            state: 'error',
            options: [],
          });
        },
      });
  }

  facetToggle(field: FacetField) {
    this.toggleFacet.emit(field);
  }

  optionToggle(facetOption: {facet: string; label: string; selected: boolean}) {
    const option = {
      FacetName: this.field,
      FacetValue: facetOption.facet,
      Selected: facetOption.selected,
    };
    this.toggleOption.emit(option);
  }

  clearFilters() {
    this.clearFacet.emit();
  }

  actionClick() {
    this.performAction.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
