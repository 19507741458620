<button (click)="facetToggle(field)" [attr.aria-expanded]="activeFacet === field" [attr.aria-controls]="'sect-' + field" [id]="'accordion-' + field" [class]="activeFacet === field ? 'border-tertiary-100' : 'border-transparent'" class="relative z-10 w-full text-sm font-bold rounded-md border-2 hover:border-tertiary-100 transition-colors duration-200 group">
  <span [class]="activeFacet === field ? 'shadow-none' : ''" class="tray w-full h-[46px] items-center bg-white rounded shadow-inset-md group-hover:shadow-none">
    @if (icon) {
      <span [class]="activeFacet === field ? 'bg-tertiary-100' : ''" class="flex justify-center items-center min-w-0 h-[30px] rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
        <facet-icon [icon]="icon" fill="white"></facet-icon>
      </span>
    }
    @if (selected$ | async; as selected) {
      <span class="min-w-0 inline-block text-left">
        <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
        <span class="flex leading-4 font-normal">
          <span class="inline-block truncate">{{selected[0].FacetLabel}}</span>
          @if (selected.length > 1) {
            <span class="inline-block ml-1 whitespace-nowrap">+ {{selected.length - 1}}</span>
          }
        </span>
      </span>
    } @else {
      <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
    }
    <span [class]="activeFacet === field ? 'text-tertiary-100' : ''" class="min-w-0 flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
      <i [ngClass]="activeFacet === field ? 'rotate-180' : 'rotate-0'"
      class="relative inline-block -top-px ml-2 transform transition-transform duration-200">
        <icon-chevron></icon-chevron>
      </i>
    </span>
  </span>
</button>
<div [id]="'sect-' + field" role="region" [attr.aria-labelledby]="'accordion-' + field" class="bg-white">
  <div [class]="activeFacet === field ? 'block' : 'hidden'" class="px-4 py-2">
    <div class="mb-4">
      <button (click)="clearFilters()"
        class="w-full py-3 rounded focus:outline-tertiary-100 text-xs font-bold bg-true-gray-700 cursor-pointer text-white">Clear Selection</button>
    </div>
    @if (dateOptions$ | async; as dateOptions) {
      @if (dateOptions.length === 0) {
        <div class="my-3 text-center italic text-gray-400">Loading...</div>
      }
      <div class="mb-3 flex justify-center">
        <div class="relative w-20">
          <button id="year-custom-prev"
            class="z-10 absolute top-1/2 -left-9 transform -translate-y-1/2">
            <span class="block px-3 text-primary-300">
              <icon-chevron [width]="14" [class]="'rotate-90'"></icon-chevron>
            </span>
          </button>
          <swiper-container #yearSwiperRef swiperElement init="false" [config]="yearSwiperConfig">
            @for (year of dateOptions; track $index) {
              <swiper-slide>
                <div class="text-center font-bold">{{year.year}}</div>
              </swiper-slide>
            }
          </swiper-container>
          <button id="year-custom-next"
            class="z-10 absolute top-1/2 -right-9 transform -translate-y-1/2">
            <span class="block px-3 text-primary-300">
              <icon-chevron [width]="14" [class]="'-rotate-90'"></icon-chevron>
            </span>
          </button>
        </div>
      </div>
      <div>
        <swiper-container #monthSwiperRef swiperElement init="false" [config]="monthSwiperConfig">
          @for (year of dateOptions; track $index) {
            <swiper-slide>
              <ol class="opt-out grid grid-rows-3 grid-cols-4 gap-1">
                @for (month of year.months; track $index) {
                  <li>
                    <button (click)="optionToggle(month)" [disabled]="!month.available && !month.selected"
                      [ngClass]="month.selected ? 'bg-tertiary-100 font-bold text-white' : month.available ? 'bg-true-gray-200' : 'bg-true-gray-200 opacity-40 line-through'"
                      class="block w-full px-3.5 py-4 rounded text-xs">
                      <span>
                        {{ month.label }}
                      </span>
                    </button>
                  </li>
                }
              </ol>
            </swiper-slide>
          }
        </swiper-container>
      </div>
    }
  </div>
</div>