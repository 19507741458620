import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {FormControl, ReactiveFormsModule, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  filter,
  map,
  startWith,
  combineLatestWith,
  debounceTime,
  takeUntil,
} from 'rxjs/operators';
import {SiteConfigService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CloseIcon} from '@app/icons/close.icon';
import {SearchIcon} from '@app/icons';
import {CommonModule} from '@angular/common';
import {FacetIconComponent} from '@app/icons/facet-icon.component';
import {FacetField} from '@app/models';

@Component({
  selector: 'facet-standard-wide',
  imports: [
    ChevronIcon,
    CloseIcon,
    SearchIcon,
    CommonModule,
    FacetIconComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './facet-standard-wide.component.html',
  styleUrls: ['./facet-standard-wide.component.css'],
})
export class FacetStandardWideComponent implements OnInit, OnDestroy {
  @Input({required: true})
  get activeFacet(): FacetField | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: FacetField | null) {
    this._activeFacet = activeFacet;
    if (activeFacet && this.fields.includes(activeFacet)) {
      this.searchForm.setValue({
        search: '',
      });
    }
  }
  private _activeFacet: FacetField | null = null;

  @Input({required: true}) fields: FacetField[] = [];
  @Input({required: true}) defaultLabel = '';
  @Input({required: true}) search = false;
  @Input() icon = '';

  @Input({required: true}) options$!: Observable<
    {
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
    }[]
  >;

  @Input({required: true}) selected$!: Observable<
    | {
        FacetName: string;
        FacetValue: string;
        FacetLabel: string;
      }[]
    | null
  >;

  // footer action buttons
  @Input() actionLabel: string | null = null;

  filteredOptions$ = new BehaviorSubject<{
    state: 'loading' | 'error' | 'success';
    options: {
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
      Selected: boolean;
    }[];
  }>({
    state: 'loading',
    options: [],
  });

  // search form
  searchForm = new FormGroup({
    search: new FormControl(''),
  });

  get searchVal() {
    return this.searchForm.get('search')?.value;
  }

  siteID;

  @ViewChild('initFocus') initFocus!: ElementRef;

  // outputs
  @Output() toggleFacet = new EventEmitter<FacetField>();
  @Output() toggleOption = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
    Selected: boolean;
  }>();
  @Output() clearFacet = new EventEmitter();
  @Output() performAction = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor(private siteConfigService: SiteConfigService) {
    this.siteID = this.siteConfigService.getConfig().siteID;
  }

  ngOnInit(): void {
    const searchStr$ = this.searchForm.controls.search.valueChanges.pipe(
      filter((searchStr): searchStr is string => searchStr !== null),
      startWith(''),
      debounceTime(200)
    );

    this.options$
      .pipe(
        combineLatestWith(searchStr$, this.selected$),
        map(([options, searchStr, selected]) => {
          let withSelected: {
            Selected: boolean;
            FacetName: string;
            FacetValue: string;
            FacetLabel: string;
          }[] = [];

          if (!selected) {
            withSelected = options
              .map(option => {
                return {
                  ...option,
                  Selected: false,
                };
              })
              .sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
          } else {
            withSelected = options
              .filter(
                option =>
                  selected.findIndex(
                    selected => selected.FacetValue === option.FacetValue
                  ) === -1
              )
              .map(option => {
                return {
                  ...option,
                  Selected: false,
                };
              })
              .concat(
                selected.map(selected => {
                  return {
                    ...selected,
                    Selected: true,
                  };
                })
              )
              .sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
          }

          if (searchStr.length > 0) {
            return withSelected.filter(option =>
              option.FacetLabel.toLowerCase().startsWith(
                searchStr.toLowerCase()
              )
            );
          }
          return withSelected;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: res => {
          this.filteredOptions$.next({
            state: 'success',
            options: res,
          });
        },
        error: () => {
          this.filteredOptions$.next({
            state: 'error',
            options: [],
          });
        },
      });
  }

  clearSearch() {
    this.searchForm.setValue({
      search: '',
    });
    this.initFocus.nativeElement.focus();
  }

  facetToggle(field: FacetField) {
    this.toggleFacet.emit(field);
  }

  optionToggle(facetOption: {
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    Selected: boolean;
  }) {
    this.toggleOption.emit(facetOption);
  }

  clearFilters() {
    this.clearFacet.emit();
  }

  actionClick() {
    this.performAction.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
