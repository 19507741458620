import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  OnDestroy,
  CUSTOM_ELEMENTS_SCHEMA,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatestWith,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import {SiteConfigService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CommonModule} from '@angular/common';
import {FacetIconComponent} from '@app/icons/facet-icon.component';
import {monthLabels} from '../dates';
import {SwiperDirective} from '@app/directives';
import {Swiper, SwiperOptions} from 'swiper/types';
import {FacetField} from '@app/models';

@Component({
  selector: 'facet-date-accordion',
  imports: [ChevronIcon, CommonModule, FacetIconComponent, SwiperDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './facet-date-accordion.component.html',
  styleUrls: ['./facet-date-accordion.component.css'],
})
export class FacetDateAccordionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input({required: true})
  get activeFacet(): string | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: FacetField | null) {
    this._activeFacet = activeFacet;

    if (activeFacet === this.field) {
      this.resetSlideIdx$.next(true);
    }
  }
  private _activeFacet: FacetField | null = null;

  @Input({required: true}) field!: FacetField;
  @Input({required: true}) defaultLabel = '';
  @Input() icon = '';

  @Input({required: true}) options$!: Observable<
    {
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
    }[]
  >;

  @Input({required: true}) selected$!: Observable<
    | {
        FacetName: string;
        FacetValue: string;
        FacetLabel: string;
      }[]
    | null
  >;

  // footer action buttons
  @Input() actionLabel: string | null = null;

  dateOptions$: BehaviorSubject<
    {
      available: boolean;
      selected: boolean;
      year: string;
      months: {
        available: boolean;
        selected: boolean;
        facet: string;
        label: string;
      }[];
    }[]
  > = new BehaviorSubject(
    [] as {
      available: boolean;
      selected: boolean;
      year: string;
      months: {
        available: boolean;
        selected: boolean;
        facet: string;
        label: string;
      }[];
    }[]
  );

  resetSlideIdx$ = new Subject<boolean>();

  siteID;

  activeYearIdx: number | null = null;

  yearSwiperConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: {
      nextEl: '#year-custom-next',
      prevEl: '#year-custom-prev',
    },
  };

  monthSwiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
  };

  // outputs
  @Output() toggleFacet = new EventEmitter<FacetField>();
  @Output() toggleOption = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
    Selected: boolean;
  }>();
  @Output() clearFacet = new EventEmitter();
  @Output() performAction = new EventEmitter();

  yearSwiper!: Swiper;
  @ViewChild('yearSwiperRef') yearSwiperRef!: ElementRef;
  monthSwiper!: Swiper;
  @ViewChild('monthSwiperRef') monthSwiperRef!: ElementRef;

  private destroy$ = new Subject<void>();

  constructor(private siteConfigService: SiteConfigService) {
    this.siteID = this.siteConfigService.getConfig().siteID;
  }

  ngOnInit(): void {
    this.options$
      .pipe(
        combineLatestWith(this.selected$),
        map(([options, selected]) => {
          let withSelected: {
            Selected: boolean;
            FacetName: string;
            FacetValue: string;
            FacetLabel: string;
          }[] = [];

          if (!selected) {
            withSelected = options
              .map(option => {
                return {
                  ...option,
                  Selected: false,
                };
              })
              .sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
          } else {
            withSelected = options
              .filter(
                option =>
                  selected.findIndex(
                    selected => selected.FacetValue === option.FacetValue
                  ) === -1
              )
              .map(option => {
                return {
                  ...option,
                  Selected: false,
                };
              })
              .concat(
                selected.map(selected => {
                  return {
                    ...selected,
                    Selected: true,
                  };
                })
              )
              .sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
          }

          // month options
          const yearsToShow = 4;
          const startYear = new Date().getFullYear();
          const endYear = startYear + yearsToShow;

          const dateList: Array<{
            available: boolean;
            selected: boolean;
            year: string;
            months: Array<{
              available: boolean;
              selected: boolean;
              facet: string;
              label: string;
            }>;
          }> = [];

          for (let i = 0; i < endYear - startYear; i++) {
            const yearStr = (startYear + i).toString();
            dateList[i] = {
              available: false,
              selected: false,
              year: yearStr,
              months: [],
            };
            monthLabels.forEach((month, j) => {
              const monthStr = `${j + 1 < 10 ? '0' : ''}${j + 1}`;
              const labelStr = `${yearStr}-${monthStr}`; //"2024-08"
              const facetMatch = withSelected.find(
                facet => facet.FacetValue === labelStr
              );
              let facet: string;
              if (facetMatch) {
                facet = facetMatch.FacetValue;
              } else {
                facet = labelStr;
              }
              const available = facetMatch !== undefined;
              const isSelected = facetMatch?.Selected ?? false;
              if (available) dateList[i].available = true;
              if (isSelected) dateList[i].selected = true;
              dateList[i].months.push({
                available,
                selected: isSelected,
                facet,
                label: month,
              });
            });
          }
          return dateList;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: res => {
          this.dateOptions$.next(res);

          if (this.yearSwiper && !res[this.yearSwiper.activeIndex].available) {
            const availableIdx = res.findIndex(option => option.available);
            if (availableIdx > -1) {
              this.yearSwiper.slideTo(availableIdx, 0);
            }
          }
        },
        error: error => {
          this.dateOptions$.next([]);
        },
      });
  }

  ngAfterViewInit(): void {
    // link swipers
    this.yearSwiper = this.yearSwiperRef.nativeElement.swiper;
    this.monthSwiper = this.monthSwiperRef.nativeElement.swiper;
    this.yearSwiper.controller.control = this.monthSwiper;
    this.monthSwiper.controller.control = this.yearSwiper;
  }

  facetToggle(field: FacetField) {
    this.toggleFacet.emit(field);
  }

  optionToggle(facetOption: {facet: string; label: string; selected: boolean}) {
    const option = {
      FacetName: this.field,
      FacetValue: facetOption.facet,
      Selected: facetOption.selected,
    };
    this.toggleOption.emit(option);
  }

  clearFilters() {
    this.clearFacet.emit();
  }

  actionClick() {
    this.performAction.emit();
  }

  toggleYear(idx: number) {
    this.activeYearIdx = idx;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
