<button (click)="facetToggle(fields[0])" [class]="activeFacet === fields[0] ? 'border-tertiary-100' : 'border-transparent'" class="relative z-10 w-full text-sm font-bold rounded-md border-2 hover:border-tertiary-100 transition-colors duration-200 group">
  <span [class]="activeFacet === fields[0] ? 'shadow-none' : ''" class="tray w-full h-[46px] items-center bg-white rounded shadow-inset-md group-hover:shadow-none">
    @if (icon) {
      <span [class]="activeFacet === fields[0] ? 'bg-tertiary-100' : ''" class="flex justify-center items-center min-w-0 h-[20px] xl:h-[30px] rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
        <facet-icon [icon]="icon" fill="white"></facet-icon>
      </span>
    }
    @if (selected$ | async; as selected) {
      <span class="min-w-0 inline-block text-left">
        <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
        <span class="flex leading-4 font-normal">
          <span class="inline-block truncate">{{selected[0].FacetLabel}}</span>
          @if (selected.length > 1) {
            <span class="inline-block ml-1 whitespace-nowrap">+ {{selected.length - 1}}</span>
          }
        </span>
      </span>
    } @else {
      <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
    }
    <span [class]="activeFacet === fields[0] ? 'text-tertiary-100' : ''" class="min-w-0 flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
      <i [ngClass]="activeFacet === fields[0] ? 'rotate-180' : 'rotate-0'"
      class="relative inline-block -top-px ml-2 transform transition-transform duration-200">
        <icon-chevron></icon-chevron>
      </i>
    </span>
  </span>
</button>
<div [class]="{'active': activeFacet !== null, 'z-[5]': activeFacet === fields[0] }" class="dropdown-container absolute left-0 w-full top-1/2 justify-center">
  <div [class]="activeFacet === fields[0] ? 'active' : ''" class="dropdown w-full p-8 bg-off-white rounded-sm shadow-lg">
    @if (search) {
      <form [formGroup]="searchForm" class="relative max-w-xl mx-auto">
        <input #initFocus formControlName="search" type="text" [ngClass]="searchVal && searchVal.length > 0 ? 'pr-14' : 'pr-3'"
          class="w-full pl-3 py-3 text-center bg-white text-xs text-black font-bold rounded shadow-inset-sm focus:outline-tertiary-200 placeholder:font-normal"
          placeholder="Search for a {{ defaultLabel }}...">
        @if (searchVal && searchVal.length > 0) {
        <div class="absolute right-0 top-0 bottom-0 flex justify-center">
          <button (click)="clearSearch()" aria-label="Clear Search"
            class="flex justify-center items-center px-4 rounded hover:bg-tertiary-200 hover:text-white focus:outline-none focus:bg-tertiary-200 focus:text-white text-black">
            <icon-close [height]="12"></icon-close>
          </button>
        </div>
        }
      </form>
      <hr class="h-0.5 my-4 bg-light-gray">
    }

    @if (filteredOptions$ | async; as filteredOptions) {
      <div class="max-h-56 overflow-y-auto">
        @switch (filteredOptions.state) {
          @case ('loading') {
            <div class="my-3 text-center italic text-gray-400">Loading...</div>
          }
          @case ('error') {
            <div class="my-3 text-center italic text-gray-400">Sorry, an error occured. Please try again later.</div>
          }
          @default {
            @if(filteredOptions.options.length > 0) {
              <ol class="opt-out m-0 grid gap-2.5 grid-cols-3 xl:grid-cols-4">
                @if (!searchForm.controls.search.value) {
                  @if (selected$ | async; as selected) {
                    <li>
                      <button (click)="clearFilters()" class="bg-true-gray-200 hover:bg-tertiary-100 group w-full h-full py-3 px-4 rounded-sm text-sm focus:outline-tertiary-200">
                        <span class="group-hover:text-white">Any {{defaultLabel}}
                        </span>
                      </button>
                    </li>
                  } @else {
                    <li class="opt-out w-full py-3 px-4 text-center rounded-sm text-sm text-white focus:outline-tertiary-200 bg-tertiary-200 font-bold">Any {{defaultLabel}}
                    </li>
                  }
                }
                @for (facetOption of filteredOptions.options; track $index) {
                  <li>
                    <button (click)="optionToggle(facetOption)" [class]="facetOption.Selected ? 'bg-tertiary-200 font-bold' : 'bg-true-gray-200 hover:bg-tertiary-100'" class="group w-full h-full py-3 px-4 rounded-sm text-sm focus:outline-tertiary-200">
                      <span [class]="facetOption.Selected 
                        ? 'text-white' 
                        : 'group-hover:text-white'">
                        {{ facetOption.FacetLabel }}
                      </span>
                    </button>
                  </li>
                }
              </ol>
            } @else {
              <div class="my-3 text-center italic text-gray-400">No Matching Results</div>
            }
          }
        }
      </div>
    }

    <hr class="h-0.5 my-4 bg-light-gray">
    <div class="flex justify-between">
      <button (click)="clearFilters()" class="py-3 px-6 rounded bg-gray-alt-200  border-2 border-gray-alt-100 text-white shadow-lg hover:bg-gray-alt-100">
        <span class="mr-2.5 uppercase font-bold text-sm">clear selection</span>
        <icon-close [height]="12" class="relative -top-px"></icon-close>
      </button>
      @if (actionLabel) {
        <button (click)="actionClick()" class="py-3 px-6 rounded bg-primary-200 hover:bg-primary-300 border-2 border-primary-100 text-white shadow-lg">
          <icon-search [fill]="'white'" class="relative -top-px"></icon-search>
          <span class="ml-2.5 uppercase font-bold text-sm">{{actionLabel}}</span>
        </button>
      }
    </div>
  </div>
</div>